var gt = Object.defineProperty;
var yt = (t, e, n) => e in t ? gt(t, e, { enumerable: !0, configurable: !0, writable: !0, value: n }) : t[e] = n;
var x = (t, e, n) => (yt(t, typeof e != "symbol" ? e + "" : e, n), n), me = (t, e, n) => {
  if (!e.has(t))
    throw TypeError("Cannot " + n);
};
var l = (t, e, n) => (me(t, e, "read from private field"), n ? n.call(t) : e.get(t)), f = (t, e, n) => {
  if (e.has(t))
    throw TypeError("Cannot add the same private member more than once");
  e instanceof WeakSet ? e.add(t) : e.set(t, n);
}, g = (t, e, n, r) => (me(t, e, "write to private field"), r ? r.call(t, n) : e.set(t, n), n);
var je = (t, e, n, r) => ({
  set _(i) {
    g(t, e, i, n);
  },
  get _() {
    return l(t, e, r);
  }
}), p = (t, e, n) => (me(t, e, "access private method"), n);
import wt from "lodash.debounce";
class D extends Error {
  constructor(n, r, i) {
    super(n);
    x(this, "_context", null);
    x(this, "_isCritical", !1);
    if (this.name = "CgError", r && (this._context = r), i && typeof i == "object") {
      const { cause: o, critical: s } = i;
      o instanceof Error && (this.cause = o), typeof s == "boolean" && (this._isCritical = s);
    }
  }
  isCritical() {
    return this._isCritical;
  }
  toString() {
    let n = `${this._isCritical ? "[CRITICAL] " : ""}[${this.name}] ${this.message}`;
    return this._context && (n += ` Context: ${JSON.stringify(this._context)}`), this.cause && (n += ` Cause: [${this.cause.toString()}]`), n;
  }
}
class mt {
  constructor() {
    x(this, "params", {});
  }
  set(e, n) {
    this.params[e] = n;
  }
  toString() {
    return Object.keys(this.params).map((e) => `${encodeURIComponent(e)}=${encodeURIComponent(this.params[e])}`).join("&");
  }
}
function St(t) {
  return new Promise((e) => {
    const n = setTimeout(() => {
      e(n);
    }, t);
  });
}
function Je(t, e, n = !1, r = null) {
  const i = Number.parseFloat(t), o = (s) => {
    if (n) {
      const a = Math.floor(s);
      return Math.max(a, e);
    }
    return s;
  };
  return Number.isNaN(i) ? o(r !== null ? Math.max(r, e) : e) : o(Math.max(i, e));
}
function We(t, e, n = !1, r = null) {
  const i = Number.parseFloat(t), o = (s) => {
    if (n) {
      const a = Math.ceil(s);
      return Math.min(a, e);
    }
    return s;
  };
  return Number.isNaN(i) ? o(r !== null ? Math.min(r, e) : e) : o(Math.min(i, e));
}
function z(t, e, n, r = !1, i = !1, o = "lower") {
  let s = null;
  switch (o) {
    case "upper":
      s = n;
      break;
    case "lower":
      s = e;
      break;
    default:
      typeof o == "number" && (s = o);
  }
  const a = Je(t, e, i, s);
  return We(a, n, r, s);
}
function bt() {
  let t = !1;
  try {
    t = window.navigator.javaEnabled();
  } catch {
  }
  return {
    language: window.navigator.language,
    colorDepth: window.screen.colorDepth,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    timezone: (/* @__PURE__ */ new Date()).getTimezoneOffset(),
    javaEnabled: t,
    javascriptEnabled: !0
  };
}
function vt(t, e = Et()) {
  typeof e.append == "function" ? e.append(t) : e.appendChild(t);
}
function Et() {
  return document.body || document.querySelector("body") || document.documentElement;
}
function xt() {
  return typeof TextEncoder < "u" ? new TextEncoder() : {
    encode: (t) => {
      const e = [];
      for (let n = 0; n < t.length; n++) {
        let r = t.charCodeAt(n);
        r < 128 ? e.push(r) : r < 2048 ? (e.push(192 | r >> 6), e.push(128 | r & 63)) : (e.push(224 | r >> 12), e.push(128 | r >> 6 & 63), e.push(128 | r & 63));
      }
      return new Uint8Array(e);
    }
  };
}
function Tt() {
  return typeof URLSearchParams < "u" ? new URLSearchParams() : new mt();
}
function Lt(t = !1) {
  const e = {
    isSecureContext: window == null ? void 0 : window.isSecureContext,
    isSecureContextByHttps: window.location.protocol === "https:"
  }, n = ["vendor", "connection", "hardwareConcurrency", "cookieEnabled", "appCodeName", "appName", "appVersion", "platform", "product", "userAgent", "language", "onLine", "userAgentData", "globalPrivacyControl", "maxTouchPoints", "productSub", "userActivation", "webdriver", "doNotTrack"], r = navigator;
  for (const i of n)
    i in r && ![null, void 0].includes(r[i]) && (typeof r[i] == "object" ? Object.keys(r[i]).length > 0 && (e[i] = r[i]) : e[i] = r[i]);
  return t ? " Navigator: " + JSON.stringify(e) : e;
}
function qe() {
  return "randomUUID" in crypto ? crypto.randomUUID() : ze([8, 4, 4, 4, 12]);
}
function ze(t, e = "-") {
  return (Array.isArray(t) ? t : [t]).map((r) => {
    let i = "";
    do
      i += Math.floor(Math.random() * Date.now()).toString(32);
    while (i.length < r);
    return i.slice(0, r);
  }).join(e);
}
function Ct(t, e = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz") {
  let n = "";
  const r = e.length;
  let i = 0;
  for (; i < t; )
    n += e.charAt(Math.floor(Math.random() * r)), i += 1;
  return n;
}
function _t(t, e = 1, n = 1 / 0) {
  if (e > n)
    throw new TypeError("Min length cannot be greater than max length.");
  if (typeof t == "string") {
    const r = t.trim().length;
    return r >= e && r <= n;
  }
  return !1;
}
function Pt(t) {
  return t == null || typeof t == "string" && t.trim() === "" || Le(t) && t.length === 0 || I(t) && Object.keys(t).length === 0;
}
function At(t) {
  return U(t) && Ce(t, "success") === !0;
}
function U(t) {
  return t != null;
}
function I(t) {
  return t !== null && typeof t == "object" && !Array.isArray(t);
}
function Le(t) {
  return Array.isArray(t);
}
function ie(t) {
  return typeof t == "function";
}
function Ee(t, e) {
  return !I(t) || typeof e != "string" ? void 0 : e.split(".").reduce((r, i) => {
    if (F(r, i))
      return r[i];
  }, t);
}
function Ce(t, e, n = void 0) {
  return F(t, e) ? t[e] : n;
}
function Ot(t, e) {
  return !I(t) || typeof e != "string" ? !1 : e.split(".").every((n) => F(t, n));
}
function F(t, e) {
  return I(t) && e in t;
}
function B(t, e = "Unknown error") {
  if (U(t)) {
    if (t instanceof Error)
      return t.toString();
    if (F(t, "message"))
      return t.message;
    if (typeof t == "string")
      return t;
    if (I(t) || Le(t))
      return JSON.stringify(t, Object.getOwnPropertyNames(t));
    if (ie(t == null ? void 0 : t.toString))
      return t.toString();
  }
  return e;
}
async function kt(t) {
  return new Promise((e, n) => {
    try {
      if (typeof t.foundValidator == "function" && t.foundValidator()) {
        e(!0);
        return;
      }
      if (document.getElementById(t.id))
        t.foundValidator ? typeof t.foundValidator != "function" ? (console.warn("FoundValidator is not a function"), e(!1)) : t.foundValidator() === !0 ? e(!0) : n("Script injected to the DOM but not loaded.") : e(!1);
      else {
        let i = null;
        if (t.elementType === "script")
          i = document.createElement("script"), i.src = t.link, i.async = t.async ?? !1, i.defer = t.defer ?? !1;
        else if (t.elementType === "link")
          i = document.createElement("link"), i.href = t.link, i.rel = t.rel, i.as = t.as;
        else
          throw new D("Invalid elementType: " + t.elementType);
        i.id = t.id, i.addEventListener("load", () => {
          !t.foundValidator || typeof t.foundValidator == "function" && t.foundValidator() === !0 ? e(!0) : e(!1);
        }), t.elementType !== "link" && i.addEventListener("error", () => {
          n(!1);
        }), document.head.append(i);
      }
    } catch {
      n(!1);
    }
  });
}
const Rt = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  appendElement: vt,
  appendScript: kt,
  bothBarrier: z,
  createURLSearchParams: Tt,
  delayedPromise: St,
  errorToString: B,
  getBrowserInfo: bt,
  getNavigatorDump: Lt,
  getProperty: Ee,
  getPropertySave: Ce,
  getPseudoRandom: ze,
  getRandom: Ct,
  getRandomUuid: qe,
  getTextEncoder: xt,
  hasDirectProperty: F,
  hasProperty: Ot,
  isArray: Le,
  isDefined: U,
  isEmpty: Pt,
  isFunction: ie,
  isObject: I,
  isSuccess: At,
  lowerBarrier: Je,
  upperBarrier: We,
  validateString: _t
}, Symbol.toStringTag, { value: "Module" }));
var le = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {}, Be = {}, m = le && le.__classPrivateFieldGet || function(t, e, n, r) {
  if (n === "a" && !r)
    throw new TypeError("Private accessor was defined without a getter");
  if (typeof e == "function" ? t !== e || !r : !e.has(t))
    throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return n === "m" ? r : n === "a" ? r.call(t) : r ? r.value : e.get(t);
}, Se = le && le.__classPrivateFieldSet || function(t, e, n, r, i) {
  if (r === "m")
    throw new TypeError("Private method is not writable");
  if (r === "a" && !i)
    throw new TypeError("Private accessor was defined without a setter");
  if (typeof e == "function" ? t !== e || !i : !e.has(t))
    throw new TypeError("Cannot write private member to an object whose class did not declare it");
  return r === "a" ? i.call(t, n) : i ? i.value = n : e.set(t, n), n;
}, T, _;
Object.defineProperty(Be, "__esModule", { value: !0 });
class Nt {
  constructor() {
    T.set(this, !1), _.set(this, /* @__PURE__ */ new Set());
  }
  /**
   * Whether the lock is currently acquired or not. Accessing this property does not affect the
   * status of the lock.
   */
  get acquired() {
    return m(this, T, "f");
  }
  /**
   * Acquires the lock, waiting if necessary for it to become free if it is already locked. The
   * returned promise is fulfilled once the lock is acquired.
   *
   * A timeout (in milliseconds) may be optionally provided. If the lock cannot be acquired before
   * the timeout elapses, the returned promise is rejected with an error. The behavior of invalid
   * timeout values depends on how `setTimeout` handles those values.
   *
   * After acquiring the lock, you **must** call `release` when you are done with it.
   */
  acquireAsync({ timeout: e } = {}) {
    if (!m(this, T, "f"))
      return Se(this, T, !0, "f"), Promise.resolve();
    if (e == null)
      return new Promise((i) => {
        m(this, _, "f").add(i);
      });
    let n, r;
    return Promise.race([
      new Promise((i) => {
        n = () => {
          clearTimeout(r), i();
        }, m(this, _, "f").add(n);
      }),
      new Promise((i, o) => {
        r = setTimeout(() => {
          m(this, _, "f").delete(n), o(new Error("Timed out waiting for lock"));
        }, e);
      })
    ]);
  }
  /**
   * Acquires the lock if it is free and otherwise returns immediately without waiting. Returns
   * `true` if the lock was free and is now acquired, and `false` otherwise.
   *
   * This method differs from calling `acquireAsync` with a zero-millisecond timeout in that it runs
   * synchronously without waiting for the JavaScript task queue.
   */
  tryAcquire() {
    return m(this, T, "f") ? !1 : (Se(this, T, !0, "f"), !0);
  }
  /**
   * Releases the lock and gives it to the next waiting acquirer, if there is one. Each acquirer
   * must release the lock exactly once.
   */
  release() {
    if (!m(this, T, "f"))
      throw new Error("Cannot release an unacquired lock");
    if (m(this, _, "f").size > 0) {
      const [e] = m(this, _, "f");
      m(this, _, "f").delete(e), e();
    } else
      Se(this, T, !1, "f");
  }
}
var Dt = Be.default = Nt;
T = /* @__PURE__ */ new WeakMap(), _ = /* @__PURE__ */ new WeakMap();
function $(t, e) {
  try {
    return $e(se(t, (e == null ? void 0 : e.ignore) ?? [], z(e == null ? void 0 : e.maxDeep, 1, 15, !0, !0, 5), (e == null ? void 0 : e.stringify) === !0, 0), (e == null ? void 0 : e.stringify) === !0, z(e == null ? void 0 : e.maxStringLength, 0, 3e4, !0, !0, 2e4), e == null ? void 0 : e.linePrefixSpace);
  } catch (n) {
    return "Error: " + ((n == null ? void 0 : n.toString()) ?? n.message);
  }
}
function $e(t, e = !1, n = 15e3, r = void 0) {
  return e ? typeof t != "string" ? JSON.stringify(t, null, r).slice(0, n) : String(t).slice(0, n) : t;
}
function se(t, e, n, r, i) {
  if (t instanceof Date)
    return r ? t.toISOString() : t;
  if (Array.isArray(t))
    return t.map((o) => se(o, e, n, r));
  if (typeof t == "object" && t !== null) {
    if (n <= 0)
      return "==deeper==";
    if ("data" in t && "status" in t && "success" in t && "headers" in t)
      return He(t);
    if (t instanceof Error) {
      const { name: a, message: c, stack: h, cause: u, context: d } = t, w = { name: a, message: c, stack: h, cause: u };
      return "context" in t && (w.context = d), se(w, e, 3, r);
    }
    const o = ["client", "session", "token", "password", "parentElement", "parentNode", "children", "childNodes", "innerHTML", "outerHTML", "innerText", "ownerDocument", "offsetParent", "ownerElement", "googlepayClient", "window", "self", "document", "top", "parent", "state", "frames", "navigator", "doctype", "documentElement", "body", "head", "defaultView", "scripts", "scrollingElement", "firstElementChild", "lastElementChild", "activeElement", "styleSheets", "adoptedStyleSheets", "firstChild", "lastChild", "srcElement"], s = {};
    for (const a in t) {
      if (e.includes(a)) {
        s[a] = "==ignore==";
        continue;
      }
      if (o.includes(a)) {
        s[a] = "==skip==";
        continue;
      }
      s[a] = se(t[a], e, n - 1, r);
    }
    return s;
  }
  return typeof t == "function" ? "func" : t;
}
function He(t) {
  try {
    if (!t || typeof t != "object")
      return {
        error: "Invalid response object",
        details: typeof t,
        value: t
      };
    const e = {};
    return t.config && typeof t.config == "object" && (e.base = t.config.baseUrl, e.url = t.config.url ?? t.url, e.body = t.config.body, e.params = t.config.params, e.method = t.config.method, e.timeout = t.config.timeout, e.headers = t.config.headers), {
      request: e,
      response: {
        success: t.success,
        status: t.status,
        data: t.data,
        contentType: t.contentType,
        headers: t.headers,
        redirected: t.redirected
      }
    };
  } catch (e) {
    return "Fetch parse error: " + (e == null ? void 0 : e.message);
  }
}
const It = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  clearedDeepCopy: $,
  convertResponse: He,
  cutOutput: $e
}, Symbol.toStringTag, { value: "Module" }));
class Ue {
  constructor() {
    x(this, "_steps", []);
    x(this, "_start", /* @__PURE__ */ new Date());
    x(this, "_lastStep", /* @__PURE__ */ new Date());
    x(this, "_done", !1);
    this._lastStep = this._start, this._steps.push({
      message: "start",
      time: this._start.toISOString(),
      durationStart: 0
    });
  }
  step(e, n) {
    const r = /* @__PURE__ */ new Date(), i = {
      message: e,
      time: r.toISOString(),
      durationStart: r.getTime() - this._start.getTime()
    }, o = this._steps.length;
    (o >= 2 || o === 1 && e !== "done") && (this._steps[0].durationStep = 0, i.durationStep = r.getTime() - this._lastStep.getTime()), n && (i.context = n), this._steps.push(i), this._lastStep = r;
  }
  done() {
    this.step("done"), this._done = !0;
  }
  getData() {
    return this._steps;
  }
  toString() {
    return "Performance: " + JSON.stringify(this.getData()) + ` Final: ${this._done ? "Y" : "N"}`;
  }
}
var E, Z, b, H, de, L, y, ee, P, W, fe, Ge, A, q;
let C = (E = class {
  constructor(e) {
    f(this, P);
    f(this, fe);
    f(this, A);
    f(this, Z, new Dt());
    f(this, b, {});
    f(this, H, !1);
    f(this, de, 0);
    f(this, L, 0);
    f(this, y, []);
    f(this, ee, void 0);
    if (this.addTransports(e).using <= 0)
      throw new Error("No transports defined.");
  }
  getVuePluginInstaller() {
    return {
      install: (n) => {
        if (!n.version.startsWith("3."))
          throw new Error("Vue 3 is required");
        n.config.globalProperties.$cglogger = this;
      }
    };
  }
  getTransports() {
    return l(this, y);
  }
  addTransports(e) {
    const n = Array.isArray(e) ? e : [e];
    let r = 0;
    for (const i of n)
      l(this, y).some((o) => o.getId() === i.getId()) || (i.setAppTicker(l(this, ee)), l(this, y).push(i), r++);
    return g(this, L, l(this, y).length), { added: r, using: l(this, L) };
  }
  removeTransports(e) {
    const n = l(this, L), r = l(this, y).filter((o) => e.getId() !== o.getId()), i = r.length;
    return i > 0 ? (g(this, y, r), g(this, L, i), {
      removed: n - i,
      using: i
    }) : {
      removed: 0,
      using: n
    };
  }
  async info(e, n) {
    return p(this, A, q).call(this, {
      message: e,
      level: E.LEVELS.INFO,
      ...p(this, P, W).call(this, n)
    });
  }
  async warn(e, n) {
    return p(this, A, q).call(this, {
      message: e,
      level: E.LEVELS.WARN,
      ...p(this, P, W).call(this, n)
    });
  }
  async error(e, n) {
    return p(this, A, q).call(this, {
      message: e,
      level: E.LEVELS.ERROR,
      ...p(this, P, W).call(this, n)
    });
  }
  async debug(e, n) {
    return p(this, A, q).call(this, {
      message: e,
      level: E.LEVELS.DEBUG,
      ...p(this, P, W).call(this, n)
    });
  }
  async log(e, n) {
    return p(this, A, q).call(this, {
      message: e,
      level: (n == null ? void 0 : n.level) || E.LEVELS.INFO,
      ...p(this, P, W).call(this, n)
    });
  }
  start() {
    return new Ue();
  }
  with(e, n) {
    return typeof n == "object" ? !1 : n == null ? this.removeWith(e) : (l(this, b)[e] = n, g(this, H, Object.keys(l(this, b)).length > 0), !0);
  }
  removeWith(e) {
    return e in l(this, b) ? (delete l(this, b)[e], g(this, H, Object.keys(l(this, b)).length > 0), !0) : !1;
  }
  withPayment(e) {
    return this.with("payment", e);
  }
  withInstance(e) {
    return this.with("instance", e);
  }
  withAppTicker(e) {
    return g(this, ee, e), l(this, y).forEach((n) => n.setAppTicker(e)), !0;
  }
  async withPerformance(e, n, r) {
    try {
      const i = new Ue();
      let o = null;
      const s = async () => {
        i.done();
        const a = r || { level: "INFO" };
        a.level = a.level || "INFO", a.context = a.context || {}, a.context.performance = i.getData();
        const c = await this.log(e, a);
        return o && o(c), c;
      };
      if (await n({
        step: i.step.bind(i),
        getData: i.getData.bind(i)
      }, s), n.length === 2)
        return new Promise((a) => {
          o = a;
        });
      if (n.length === 1)
        return await s();
    } catch {
    }
    return !1;
  }
}, Z = new WeakMap(), b = new WeakMap(), H = new WeakMap(), de = new WeakMap(), L = new WeakMap(), y = new WeakMap(), ee = new WeakMap(), P = new WeakSet(), W = function(e) {
  const { code: n, context: r, onlyLocal: i, dispatchNow: o, ignoreLimitCheck: s, date: a } = e ?? {}, c = {
    onlyLocal: i ?? !1,
    dispatchNow: o ?? !1,
    ignoreLimitCheck: s ?? !1
  };
  return n && (c.code = Number(n)), r && (c.context = $(r)), a && (c.date = new Date(a)), l(this, H) && (c.details = $(l(this, b), { maxDeep: 1 })), c;
}, fe = new WeakSet(), Ge = async function() {
  try {
    await l(this, Z).acquireAsync();
    try {
      return ++je(this, de)._;
    } catch {
      return;
    } finally {
      l(this, Z).release();
    }
  } catch {
    return;
  }
}, A = new WeakSet(), q = async function(e) {
  try {
    if (!e.message || !e.level)
      return !1;
    typeof e.level == "string" && (e.level = e.level.toUpperCase());
    let n = !1, r = !1;
    for (let o = 0; o < l(this, L); o++) {
      const s = l(this, y)[o];
      if (s.canHandleLevel(e.level) && (n = !0, !s.isLocal())) {
        r = !0;
        break;
      }
    }
    if (!n)
      return !1;
    if (r || (e.onlyLocal = !0), e.level === "ERR" && (e.level = "ERROR"), !e.onlyLocal) {
      const o = await p(this, fe, Ge).call(this), s = e.date || /* @__PURE__ */ new Date();
      e.order = o, e.date = s;
      const a = e.details;
      e.details = {
        ...$(a),
        order: o
      };
    }
    let i = 0;
    for (let o = 0; o < l(this, L); o++) {
      const s = l(this, y)[o];
      s.canHandleLevel(e.level) && (!e.onlyLocal || e.onlyLocal && s.isLocal()) && (s.handle(e, l(this, b).payment), i++);
    }
    return i > 0;
  } catch (n) {
    return console.error("Logger error", n), !1;
  }
}, x(E, "LEVELS", {
  DEBUG: "DEBUG",
  INFO: "INFO",
  WARN: "WARN",
  ERROR: "ERROR"
}), E);
var he, O, G, te;
const Ne = class Ne {
  constructor(e, n) {
    f(this, he, qe());
    f(this, O, {});
    f(this, G, {});
    f(this, te, void 0);
    if (this.constructor === Ne)
      throw new Error("Instance of Abstract Class cannot be created");
    g(this, G, e), g(this, O, n);
  }
  getId() {
    return l(this, he);
  }
  setConfig(e, n) {
    const r = {};
    return r[e] = n, this.applyConfig(r) === 1;
  }
  getConfig(e) {
    if (e in l(this, O))
      return l(this, O)[e];
  }
  setAppTicker(e) {
    g(this, te, e);
  }
  getAppTicker() {
    return l(this, te);
  }
  handle(e, n) {
    throw new Error("TransportBase abstract method 'handle' has no implementation");
  }
  getType() {
    throw new Error("TransportBase abstract method 'handle' has no implementation");
  }
  isLocal() {
    throw new Error("TransportBase abstract method 'isLocal' has no implementation");
  }
  canIgnoreLimit() {
    return !1;
  }
  canDispatchNow() {
    return !1;
  }
  canHandleLevel(e) {
    return l(this, O)[e.toLowerCase()] === !0;
  }
  applyConfig(e) {
    if (!e || typeof e != "object")
      return 0;
    let n = 0;
    for (const r in e) {
      const i = l(this, G)[r], o = Array.isArray(i);
      r in l(this, G) && (!o && typeof e[r] === i || o && i.includes(e[r])) && (l(this, O)[r] = e[r], n++);
    }
    return n;
  }
  hole(e) {
    (e == null ? void 0 : e.catch) === "function" && e.catch((n) => {
      console.warn("Failed to send logs to Comgate server.\\nDetail:", n);
    });
  }
};
he = new WeakMap(), O = new WeakMap(), G = new WeakMap(), te = new WeakMap();
let Q = Ne;
class Ke extends Q {
  constructor(e) {
    super({
      debug: "boolean",
      info: "boolean",
      warn: "boolean",
      error: "boolean",
      prefix: "string"
    }, {
      debug: !0,
      info: !0,
      warn: !0,
      error: !0,
      prefix: ""
    }), super.applyConfig(e);
  }
  handle(e, n) {
    var r, i, o;
    try {
      let s = e.level;
      e.onlyLocal && (s = "LOCAL " + s), e.level === C.LEVELS.DEBUG && (s = ` ${s} `);
      const a = `%c${this.getConfig("prefix")}${s}`;
      let c = "";
      e.order && (c += ` (${e.order})`), e.code && (c += ` <${e.code}>`), (n || (r = e.details) != null && r.payment) && (c += ` [${n || ((i = e.details) == null ? void 0 : i.payment)}]`), (o = e.details) != null && o.instance && (c += ` {${e.details.instance}}`), c += ":";
      const h = [c.trim(), e.message];
      switch (e.context && (h.push("\\nContext:"), h.push(e.context)), e.level) {
        case C.LEVELS.DEBUG:
          console.log(a, "color:#fff;background:#444;font-weight: bold", ...h);
          break;
        case C.LEVELS.INFO:
          console.log(a, "color:#0099e5;font-weight: bold", ...h);
          break;
        case C.LEVELS.WARN:
          console.log(a, "color:#FFA500;font-weight: bold", ...h);
          break;
        case C.LEVELS.ERROR:
          console.log(a, "color:#ff221e;font-weight: bold", ...h);
          break;
        default:
          return console.log("Invalid level.", e), !1;
      }
      return !0;
    } catch {
    }
    return !1;
  }
  isLocal() {
    return !0;
  }
  canIgnoreLimit() {
    return !0;
  }
  getType() {
    return "ConsoleTransport";
  }
}
const _e = typeof Buffer == "function";
typeof TextDecoder == "function" && new TextDecoder();
const Fe = typeof TextEncoder == "function" ? new TextEncoder() : void 0, jt = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=", Y = Array.prototype.slice.call(jt);
((t) => {
  let e = {};
  return t.forEach((n, r) => e[n] = r), e;
})(Y);
const S = String.fromCharCode.bind(String);
typeof Uint8Array.from == "function" && Uint8Array.from.bind(Uint8Array);
const Ut = (t) => t.replace(/=/g, "").replace(/[+\/]/g, (e) => e == "+" ? "-" : "_"), Ft = (t) => {
  let e, n, r, i, o = "";
  const s = t.length % 3;
  for (let a = 0; a < t.length; ) {
    if ((n = t.charCodeAt(a++)) > 255 || (r = t.charCodeAt(a++)) > 255 || (i = t.charCodeAt(a++)) > 255)
      throw new TypeError("invalid character found");
    e = n << 16 | r << 8 | i, o += Y[e >> 18 & 63] + Y[e >> 12 & 63] + Y[e >> 6 & 63] + Y[e & 63];
  }
  return s ? o.slice(0, s - 3) + "===".substring(s) : o;
}, Xe = typeof btoa == "function" ? (t) => btoa(t) : _e ? (t) => Buffer.from(t, "binary").toString("base64") : Ft, Vt = _e ? (t) => Buffer.from(t).toString("base64") : (t) => {
  let n = [];
  for (let r = 0, i = t.length; r < i; r += 4096)
    n.push(S.apply(null, t.subarray(r, r + 4096)));
  return Xe(n.join(""));
}, Mt = (t) => {
  if (t.length < 2) {
    var e = t.charCodeAt(0);
    return e < 128 ? t : e < 2048 ? S(192 | e >>> 6) + S(128 | e & 63) : S(224 | e >>> 12 & 15) + S(128 | e >>> 6 & 63) + S(128 | e & 63);
  } else {
    var e = 65536 + (t.charCodeAt(0) - 55296) * 1024 + (t.charCodeAt(1) - 56320);
    return S(240 | e >>> 18 & 7) + S(128 | e >>> 12 & 63) + S(128 | e >>> 6 & 63) + S(128 | e & 63);
  }
}, Jt = /[\uD800-\uDBFF][\uDC00-\uDFFFF]|[^\x00-\x7F]/g, Wt = (t) => t.replace(Jt, Mt), Ve = _e ? (t) => Buffer.from(t, "utf8").toString("base64") : Fe ? (t) => Vt(Fe.encode(t)) : (t) => Xe(Wt(t)), be = (t, e = !1) => e ? Ut(Ve(t)) : Ve(t);
class X {
  constructor(e = {}) {
    F(e, "forEach") && ie(e.forEach) ? e.forEach(([n, r]) => {
      this[n.toLowerCase()] = r;
    }) : I(e) && Object.entries(e).forEach(([n, r]) => {
      this[n.toLowerCase()] = r;
    });
  }
  get(e) {
    return this[e.toLowerCase()];
  }
  set(e, n) {
    this[e.toLowerCase()] = n;
  }
  static create(e = {}) {
    const n = new X(e);
    return new Proxy(n, {
      get(r, i) {
        if (typeof i == "string")
          return r.get(i);
        if (F(r, i))
          return r[i];
      },
      set(r, i, o) {
        return typeof i == "string" ? (r.set(i, o), !0) : !1;
      }
    });
  }
  [Symbol.iterator]() {
    let e = 0;
    const n = Object.keys(this);
    return {
      next: () => {
        if (e < n.length) {
          const r = n[e++];
          return { value: [r, this[r]], done: !1 };
        }
        return { done: !0 };
      }
    };
  }
}
let ae = "https://payments.comgate.cz";
const qt = 3e4;
async function zt(t, e = 200, n = {}, r = {}) {
  return new Promise((i, o) => {
    Pe({
      url: t,
      params: n,
      method: "GET"
    }, e, i, o, Ze(r));
  });
}
async function Ye(t, e = 200, n = {}, r = {}, i = {}) {
  return new Promise((o, s) => {
    Pe({
      url: t,
      params: n,
      method: "POST",
      body: r
    }, e, o, s, Ze(i));
  });
}
function Qe(t) {
  return typeof t == "string" && /^https:\/\/[a-zA-Z0-9_.-]+/.test(t);
}
function Bt(t) {
  let e = !0, n = t;
  return t === void 0 ? (ae = void 0, e = !1) : n && typeof n == "string" && (n = n.trim(), n.endsWith("/") && (n = n.slice(0, -1)), n.startsWith("http://") && (!window.Cypress || location.hostname !== "localhost") && (n = n.replace("http://", "https://"), console.warn(`Checkout SDK: Changing HTTP of base domain to HTTPS: '${n}'`)), Qe(n) && (ae = n, e = !1)), e && console.warn(`Checkout SDK: Invalid base domain: '${n}'. Using default domain: '${ae}'`), !e;
}
function Ze(t) {
  const { maxRetries: e, repeatDelay: n, responseValidator: r, timeout: i, checkSuccessParam: o, strictMaxRetries: s, headers: a, requireJson: c } = t, h = c !== !1;
  return {
    checkSuccessParam: h ? o !== !1 : !1,
    strictMaxRetries: s !== !1,
    repeatDelay: z(n, 0, 5e3, !0, !0, 500),
    maxRetries: z(e, 0, 10, !0, !0, 1),
    responseValidator: U(r) && ie(r) ? r : null,
    timeout: z(i, 0, 999999, !0, !0, qt),
    headers: X.create(a),
    requireJson: h
  };
}
async function Me(t, e, n) {
  try {
    if (!U(n.responseValidator))
      return { valid: !0, details: "No custom validator provided" };
    if (!ie(n.responseValidator))
      return { valid: !1, details: "Provided validator is not a function" };
    let r;
    try {
      r = await n.responseValidator({
        response: t,
        request: e,
        customConfig: n,
        json: t == null ? void 0 : t.json,
        isJson: t.rawFormat === "json",
        data: t.raw,
        dataFormat: t.rawFormat
      });
    } catch (i) {
      return {
        valid: !1,
        details: "Error caught from provided validator: " + B(i)
      };
    }
    return r === !0 ? {
      valid: !0,
      details: "Custom validation passed"
    } : typeof r == "string" ? {
      valid: !1,
      details: r
    } : {
      valid: !1,
      details: "Detail not provided"
    };
  } catch (r) {
    return {
      valid: !1,
      details: "System error caught: " + B(r)
    };
  }
}
async function Pe(t, e = 200, n, r, i, o = !1, s = !1, a = 0, c = 0) {
  try {
    if (!t.url)
      throw new D("Missing URL");
    const h = tt(t.url, ae);
    t.url = h.url, t.baseUrl = h.base, t.headers || (t.headers = X.create()), t.headers["X-Requested-With"] = "XMLHttpRequest", t.headers["X-Cg-Origin"] = window.location.origin, t.timeout = i.timeout, et(t, i).then(async (u) => {
      const d = {
        message: "Unknown error",
        errorCode: "UNKNOWN-ERROR",
        valid: !1,
        response: u
      };
      if (Array.isArray(e) && (e.length !== 2 || e[0] > e[1]) && console.warn("Invalid successCode array " + JSON.stringify(e) + " the first number must be lower or equal than the second one"), Array.isArray(e) ? u.status && u.status >= e[0] && u.status && u.status <= e[1] : u.status && u.status === e) {
        if (i.requireJson && u.status !== 204) {
          if (u.rawFormat === "json") {
            if (i.checkSuccessParam && Ce(u.json, "success") === !0 || !i.checkSuccessParam) {
              const M = await Me(u, t, i);
              if (M.valid) {
                n(ve(u, t, i, { successCode: e, retryCount: c, networkErrorCount: a }));
                return;
              }
              return d.message = "Invalid (JSON) by custom validator: " + M.details, d.errorCode = "INVALID-JSON-BY-VALIDATOR", Promise.reject(d);
            }
            return d.message = "Incorrect return data. Expected JSON with 'success=true' param.", d.errorCode = "INVALID-JSON-SUCCESS-PARAM", Promise.reject(d);
          }
          return d.message = "Incorrect return data format. Expected JSON.", d.errorCode = "INVALID-DATA-JSON-EXPECTED", Promise.reject(d);
        }
        const V = await Me(u, t, i);
        if (V.valid) {
          n(ve(u, t, i, { successCode: e, retryCount: c, networkErrorCount: a }));
          return;
        }
        return d.message = "Invalid (non-JSON) by custom validator: " + V.details, d.errorCode = "INVALID-NONJSON-BY-VALIDATOR", Promise.reject(d);
      }
      return d.message = "Invalid response status code.", d.errorCode = "INVALID-RESPONSE-CODE", Promise.reject(d);
    }).catch((u) => {
      const d = (u == null ? void 0 : u.code) ?? 0, w = d === DOMException.TIMEOUT_ERR, V = d === DOMException.NETWORK_ERR;
      let M, we;
      Ee(u, "valid") === !1 ? we = B(u, "Invalid response validation") : we = B(u);
      const De = Ee(u, "response");
      De ? (M = De, delete u.response) : M = u;
      let J = a;
      if (w && !o || V && !s ? J += 1 : J = 0, !i.strictMaxRetries && J <= 2 || i.maxRetries > c) {
        let Ie = c + 1;
        (w || V) && J < 1 && !i.strictMaxRetries && (Ie = c), setTimeout(() => {
          Pe(t, e, n, r, i, w, V, J, Ie);
        }, i.repeatDelay);
        return;
      }
      r(ve(M, t, i, {
        eCode: d,
        eMessage: we,
        successCode: e,
        networkErrorCount: J,
        retryCount: c
      }));
    });
  } catch (h) {
    r({
      error: new D("System error: " + B(h))
    });
  }
}
function ve(t, e, n, r) {
  const i = I(t) ? t : {}, o = {
    json: i.json,
    raw: i.raw,
    rawFormat: i.rawFormat,
    contentType: i.contentType,
    status: i.status,
    success: i.success,
    headers: i.headers,
    request: e,
    details: {
      checkSuccessParam: n.checkSuccessParam,
      networkErrorCount: r.networkErrorCount,
      retries: r.retryCount,
      maxRetries: n.maxRetries,
      redirected: i.redirected,
      expectedStatusCode: r.successCode,
      url: i.url
    }
  };
  return (U(r.eCode) || U(r.eMessage)) && (o.error = {
    code: r.eCode,
    message: r.eMessage
  }), o;
}
async function et(t, e) {
  const n = Gt(t.url, t.params), r = {
    method: t.method,
    headers: t.headers,
    signal: $t(e.timeout),
    mode: "cors",
    redirect: "follow"
  };
  ["POST", "PUT", "PATCH"].includes(t.method) && t.body && (r.body = JSON.stringify(t.body), r.headers["Content-Type"] = "application/json");
  const i = await fetch(n, r), { json: o, raw: s, type: a, contentType: c, error: h } = await Ht(i, e), u = {
    json: o,
    raw: s,
    rawFormat: a,
    contentType: c,
    status: i.status,
    redirected: i.redirected,
    success: i.ok,
    headers: X.create(i.headers),
    url: i.url,
    request: r
  };
  return ["error", "no-response"].includes(a) ? Promise.reject({
    response: u,
    error: h
  }) : u;
}
function $t(t) {
  if (typeof AbortController < "u") {
    const e = new AbortController();
    return setTimeout(() => e.abort(), t), e.signal;
  }
  return typeof AbortSignal < "u" ? AbortSignal.timeout(t) : null;
}
async function Ht(t, e) {
  var r;
  if (!t)
    return { type: "no-response", json: void 0, raw: void 0, contentType: void 0, error: void 0 };
  const n = (r = t.headers.get("Content-Type")) == null ? void 0 : r.toLowerCase();
  if (t.status === 204)
    return { type: "no-content", json: void 0, raw: void 0, contentType: n, error: void 0 };
  try {
    const i = await t.text();
    if ((n == null ? void 0 : n.includes("application/json")) && i && e.requireJson)
      try {
        return { type: "json", json: JSON.parse(i), raw: i, contentType: n };
      } catch (s) {
        return { type: "error", json: void 0, raw: i, contentType: n, error: s };
      }
    else
      return { type: "text", json: void 0, raw: i, contentType: n };
  } catch (i) {
    return { type: "error", json: void 0, raw: void 0, contentType: n, error: i };
  }
}
function Gt(t, e) {
  const n = new URL(t);
  return e && typeof e == "object" && Object.keys(e).forEach((r) => n.searchParams.append(r, e[r])), n.toString();
}
function tt(t, e) {
  const n = t;
  return n.startsWith("/") && e ? {
    url: e + n,
    base: e
  } : {
    url: n,
    base: void 0
  };
}
const Kt = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  changeBaseDomain: Bt,
  expandUrl: tt,
  get: zt,
  isDomainValid: Qe,
  makeRawRequest: et,
  post: Ye
}, Symbol.toStringTag, { value: "Module" }));
var v, k, R, N, K, ne, xe, re, Te, pe, rt, ge, it;
class nt extends Q {
  constructor(n) {
    super({
      debug: "boolean",
      info: "boolean",
      warn: "boolean",
      error: "boolean",
      debounce: "number",
      maxWait: "number",
      maxStack: "number"
    }, {
      debug: !1,
      info: !0,
      warn: !0,
      error: !0,
      debounce: 2e3,
      maxWait: 1e4,
      maxStack: 20
    });
    f(this, ne);
    f(this, re);
    f(this, pe);
    f(this, ge);
    f(this, v, []);
    f(this, k, void 0);
    f(this, R, void 0);
    f(this, N, {
      aborted: !1,
      time: (/* @__PURE__ */ new Date()).getTime(),
      count: 0
    });
    f(this, K, !1);
    g(this, v, []), super.applyConfig(n), p(this, ne, xe).call(this);
  }
  getQueueSize() {
    return l(this, v).length;
  }
  handle(n, r) {
    if ((n.ignoreLimitCheck || [C.LEVELS.WARN, C.LEVELS.ERROR].includes(n.level)) && g(this, K, !0), !p(this, ge, it).call(this))
      return !1;
    r && g(this, R, r);
    const i = {
      message: n.message,
      level: n.level,
      code: n.code,
      order: n.order,
      date: n.date ?? /* @__PURE__ */ new Date()
    };
    if (n.context && (i.context = $(n.context)), n.details && (i.details = $(n.details)), l(this, v).push(i), n.dispatchNow || l(this, v).length >= this.getConfig("maxStack"))
      this.hole(this.dispatchNow());
    else if (l(this, k))
      l(this, k).call(this);
    else
      return !1;
    return !0;
  }
  dispatchNow() {
    var n;
    return (n = l(this, k)) == null || n.cancel(), p(this, re, Te).call(this);
  }
  applyConfig(n) {
    const r = super.applyConfig(n);
    if (!n || r <= 0)
      return 0;
    const i = Object.keys(n);
    return i.some((o) => ["debounce", "maxWait"].includes(o)) && p(this, ne, xe).call(this), i.includes("maxStack") && l(this, v).length > this.getConfig("maxStack") && this.hole(this.dispatchNow()), r;
  }
  isLocal() {
    return !1;
  }
  canIgnoreLimit() {
    return !0;
  }
  canDispatchNow() {
    return !0;
  }
  getType() {
    return "ServerTransport";
  }
}
v = new WeakMap(), k = new WeakMap(), R = new WeakMap(), N = new WeakMap(), K = new WeakMap(), ne = new WeakSet(), xe = function() {
  var n;
  (n = l(this, k)) == null || n.flush(), g(this, k, wt(() => this.hole(p(this, re, Te).call(this)), this.getConfig("debounce"), { maxWait: this.getConfig("maxWait") }));
}, re = new WeakSet(), Te = async function() {
  const n = l(this, v);
  if (g(this, v, []), n.length <= 0)
    return Promise.resolve({
      invalid: !1,
      count: 0,
      loggerEnabled: this.getConfig("enabled"),
      stackSize: n.length,
      server: null,
      error: null
    });
  const r = l(this, K);
  g(this, K, !1);
  const i = p(this, pe, rt).call(this, n);
  return Ye("/checkout/journal", 204, {
    ignoreLimitCheck: r ? 1 : 0,
    ...l(this, R) && { id: l(this, R) }
  }, i, {
    maxRetries: 1,
    repeatDelay: 500,
    checkSuccessParam: !1
  }).then(() => Promise.resolve({
    invalid: !1,
    count: n.length
  })).catch((o) => Promise.resolve({
    invalid: !0,
    count: n.length,
    stackSize: this.getConfig("maxStack"),
    error: o
  }));
}, pe = new WeakSet(), rt = function(n) {
  const r = {
    ...l(this, R) && { transId: l(this, R) },
    encoded: !0,
    logs: []
  }, i = this.getAppTicker();
  return i && (r.appTicker = i), r.logs = n.map((o) => {
    const { level: s, date: a, code: c, order: h, message: u, context: d, details: w } = o;
    return {
      level: s,
      dt: a.toISOString(),
      ...c && { code: c },
      ...h && { order: h },
      message: be(u),
      ...d && { context: be(JSON.stringify(d)) },
      ...w && { details: be(JSON.stringify(w)) }
    };
  }), r;
}, ge = new WeakSet(), it = function() {
  const { aborted: n, time: r, count: i } = l(this, N);
  if (n)
    return !1;
  const o = window.Cypress ? 45 : 5, s = (/* @__PURE__ */ new Date()).getTime();
  return i > o && s - r < 1500 ? (l(this, N).aborted = !0, !1) : (s - r < 1500 ? ++l(this, N).count : (l(this, N).count = 0, l(this, N).time = (/* @__PURE__ */ new Date()).getTime()), !0);
};
function ot(t) {
  return new C(t);
}
const ce = ot([new Ke(), new nt()]), Xt = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  BaseTransport: Q,
  ConsoleTransport: Ke,
  Logger: C,
  LoggerInstance: ce,
  ServerTransport: nt,
  createLogger: ot,
  default: ce
}, Symbol.toStringTag, { value: "Module" }));
class j {
  static validateUrl(e) {
    return /^http(|s):\/\/.*/i.test(e);
  }
  static redirectGet(e, n = "self", ...r) {
    if (!e || typeof e != "string" || !j.validateUrl(e))
      throw new D("Cannot GET redirect to invalid URL address.", r);
    const i = j.getRedirectWindow(n);
    try {
      i.location.href = e;
    } catch (o) {
      ce.error("Cannot redirect to URL. Use failsafe.", { context: { url: e, redirectMode: n, error: o } }), window.location.href = e;
    }
  }
  static redirectPost(e, n, r, i = "self", ...o) {
    if (!e || typeof e != "string" || !j.validateUrl(e))
      throw new D("Cannot POST redirect to invalid URL address.", o);
    const s = document.createElement("form");
    s.action = e, s.method = "POST", s.enctype = "multipart/form-data", s.target = j.getFormEscapeTarget(i), typeof document.body.append == "function" ? document.body.append(s) : document.body.appendChild(s);
    for (const a in n) {
      const c = document.createElement("input");
      c.type = "hidden", c.name = a, c.name = a, c.value = n[a], s.append(c);
    }
    r && (s.enctype = r, s.encoding = r), s.submit();
  }
  static redirectFormPost(e, n = "self") {
    if (!e || typeof e != "string")
      throw new D("Cannot redirect to invalid form.", { formDef: e, redirectMode: n });
    const r = document.createElement("div");
    r.innerHTML = e;
    const i = r.firstChild;
    if (i && i instanceof HTMLFormElement)
      i.target = j.getFormEscapeTarget(n), document.body.append(i), i.submit();
    else
      throw new D("Cannot get form from virtual DIV.", { formDef: e, redirectMode: n });
  }
  static getFormEscapeTarget(e = "self") {
    switch (e) {
      case "parent":
        return "_parent";
      case "top":
        return "_top";
      case "self":
      default:
        return "_self";
    }
  }
  static getRedirectWindow(e = "self") {
    return e === "top" && window.top ? window.top : e === "parent" && window.parent ? window.parent : e === "self" && window.self ? window.self : (ce.warn("Unknown redirect mode. Redirecting to self window.", { context: { redirectMode: e } }), window);
  }
}
const ye = "PENDING", Ae = "PAID", Oe = "AUTHORIZED", ke = "CANCELLED", st = "CANCELED", at = [Ae, Oe, ye, ke, st], lt = [Ae], Yt = [ye], ct = [Oe], ut = [ke, st];
function oe(t) {
  return t == null ? void 0 : t.toString().toUpperCase().trim();
}
function Qt(t) {
  return at.includes(oe(t));
}
function dt(t) {
  return lt.includes(oe(t));
}
function ft(t) {
  return ct.includes(oe(t));
}
function ht(t) {
  return ut.includes(oe(t));
}
function pt(t) {
  return dt(t) || ft(t);
}
function Zt(t) {
  return !ue(t);
}
function ue(t) {
  return pt(t) || ht(t);
}
function en(t, e) {
  return ue(t) ? t : ue(e) ? e : ye;
}
const tn = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  authorizedState: ct,
  cancelledState: ut,
  getFinalOrPending: en,
  isAuthorized: ft,
  isCancelled: ht,
  isFinal: ue,
  isPaid: dt,
  isPaidOrAuthorized: pt,
  isPending: Zt,
  isValidStatus: Qt,
  normalizeStatus: oe,
  paidState: lt,
  paymentStates: at,
  pendingState: Yt,
  statusAuthorized: Oe,
  statusCancelled: ke,
  statusPaid: Ae,
  statusPending: ye
}, Symbol.toStringTag, { value: "Module" })), Re = /[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}/gim;
function nn(t) {
  return typeof t == "string" ? Re.test(t) : !1;
}
function rn(t = window.location.toString()) {
  var e;
  try {
    const n = Re.exec(t), r = (e = n == null ? void 0 : n.groups) == null ? void 0 : e.agmo;
    if (r)
      return r;
  } catch (n) {
    console.error(n);
  }
  return null;
}
const on = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  getPaymentId: rn,
  isTransIdValid: nn,
  transIdRegex: Re
}, Symbol.toStringTag, { value: "Module" })), ln = {
  Helpers: Rt,
  RequestService: Kt,
  Logger: Xt,
  CgError: D,
  Sanitizer: It,
  Redirector: j,
  StatusUtils: tn,
  TransactionUtils: on,
  SmartObject: X
};
export {
  D as CgError,
  Rt as Helpers,
  Xt as Logger,
  j as Redirector,
  Kt as RequestService,
  It as Sanitizer,
  X as SmartObject,
  tn as StatusUtils,
  on as TransactionUtils,
  ln as default
};
